<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">קליטת דוח חדש</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <div>
                        <!-- <b-field label="פורמט להורדה">
                            <b-button @click="downloadUsersFormat">הורד</b-button>
                        </b-field> -->
                        <b-field label="בחירת קובץ">
                            <b-upload v-model="file" class="file-label">
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>
                                    <span class="file-label">בחירת קובץ</span>
                                </span>
                                <span class="file-name" v-if="file">
                                    {{ file.name }}
                                </span>
                            </b-upload>
                        </b-field>
                        <b-field label="תאריך לדוחות">
                            <FieldInlineDate @onChange="onChange" v-model="model.date" />
                        </b-field>
                        <b-checkbox style="gap: 10px" v-model="model.isTest" label="" type="is-link is-light"
                            class="checkbox">
                            לשייך למשתמש טסטים?
                        </b-checkbox>
                        <b-input style="width: 30%" placeholder="מספר תלמיד" type="number" v-model="testUserId" v-if="model.isTest"></b-input>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" :disabled="!isValidForm" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import {
    ToastProgrammatic as Toast,
} from "buefy";
import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
import moment from "moment";
import CoursesService from "../services/CoursesService";
import { saveAs } from "file-saver";

export default {
    name: "DialogReportDriverAssign",
    props: ["reportNumbers", "reports", "onSuccess", "store"],
    components: {
        FieldInlineDate
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
        isValidForm() {
            return this.model.date && this.file && (!this.model.isTest || (this.model.isTest && this.testUserId))
        }
    },
    created() {
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            courses: null,
            tracks: null,
            radio: 'manual',
            file: null,
            testUserId: null,
            model: {
                date: null,
                isTest: false
            }
        };
    },
    methods: {
        saveFile(data, filename) {
            const blob = new Blob([data], { type: this.excelMimeType });
            saveAs(blob, `${filename}.xlsx`);
        },
        save() {
            this.isLoading = true;
            const formData = new FormData();
            formData.append('file', this.file);
            const formattedDate = moment(this.model.date).format("YYYY-MM-DD");
            CoursesService.importCourseReports(this.courseId, formattedDate, this.testUserId, formData)
                .then((res) => {
                    const succeededReports = res.data.filter((r) => r.status);
                    const failedReports = res.data.filter((r) => !r.status);
                    this.$buefy.dialog.alert({
                        title: 'יבוא דוחות בוצע בהצלחה',
                        message: `
                        </div>
                        <strong>סטטוס קליטת דוחות:</strong>
                        </div>
                        <div>הצליחו (${succeededReports.length}):</div>
                        ${succeededReports.map((r) => `<div>מספר תלמיד :${r.studentId}.</div>`).join('')}
                        <div style="margin-top: 10px;">נכשלו (${failedReports.length}):</div>
                        ${failedReports.map((r) => `<div>מספר תלמיד :${r.studentId}.</div>`).join('')}
                        `,
                        onConfirm: () => {
                            this.$emit("customEvent");
                            this.$emit("close");
                        },
                    });
                }).catch((err) => {
                    let message = '';
                    if (err.response.data.includes('Duplicate')) {
                        const newMessage = err.response.data.split(':');
                        message = 'קיימות רשומות כפולות לסטודנטים:' + newMessage[1];
                    } else {
                        switch (err.response.data) {
                            case "Invalid File":
                                message = 'הקובץ לא תקין.'
                                break;
                            default:
                                message = 'קרתה תקלה!'
                                break;
                        }
                    }
                    Toast.open({
                        type: "is-danger",
                        message,
                        duration: 4000
                    })
                }).finally(() => {
                    this.isLoading = false;
                    // this.$emit("customEvent");
                })
        },
        onChange() { },
    },
};
</script>

<style scoped lang="scss">
.courses,
.tracks {
    max-height: 200px;
    margin: 10px 0;
    overflow: auto;
}
</style>
