var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [_vm._v("קליטת דוח חדש")]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c("div", { staticClass: "DialogReportPaid__Component__Loading" }, [
          _c(
            "div",
            [
              _c(
                "b-field",
                { attrs: { label: "בחירת קובץ" } },
                [
                  _c(
                    "b-upload",
                    {
                      staticClass: "file-label",
                      model: {
                        value: _vm.file,
                        callback: function($$v) {
                          _vm.file = $$v
                        },
                        expression: "file"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "file-cta" },
                        [
                          _c("b-icon", {
                            staticClass: "file-icon",
                            attrs: { icon: "upload" }
                          }),
                          _c("span", { staticClass: "file-label" }, [
                            _vm._v("בחירת קובץ")
                          ])
                        ],
                        1
                      ),
                      _vm.file
                        ? _c("span", { staticClass: "file-name" }, [
                            _vm._v(" " + _vm._s(_vm.file.name) + " ")
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "תאריך לדוחות" } },
                [
                  _c("FieldInlineDate", {
                    on: { onChange: _vm.onChange },
                    model: {
                      value: _vm.model.date,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "date", $$v)
                      },
                      expression: "model.date"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-checkbox",
                {
                  staticClass: "checkbox",
                  staticStyle: { gap: "10px" },
                  attrs: { label: "", type: "is-link is-light" },
                  model: {
                    value: _vm.model.isTest,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "isTest", $$v)
                    },
                    expression: "model.isTest"
                  }
                },
                [_vm._v(" לשייך למשתמש טסטים? ")]
              ),
              _vm.model.isTest
                ? _c("b-input", {
                    staticStyle: { width: "30%" },
                    attrs: { placeholder: "מספר תלמיד", type: "number" },
                    model: {
                      value: _vm.testUserId,
                      callback: function($$v) {
                        _vm.testUserId = $$v
                      },
                      expression: "testUserId"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              disabled: !_vm.isValidForm,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }