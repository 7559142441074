<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>דוחות בקורס</template>
      <template #button>
        <a @click="addReport" class="link-button">
          קליטת דוח חדש
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import CoursesService from "@/services/CoursesService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import TableActions from "@/services/TableActionsService";
import UserEntity from "@/views/UserEntity.vue";
import FilterService from "@/services/FilterService";
import DialogAddReport from '@/components/DialogAddReport.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";

export default {
  name: "CourseReports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const reportsFields = FieldService.list("reports");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: UserEntity,
        preview: true,
        readOnly: false,
        title: "פרטי דוח",
        recordUrl: "report",
      };

      const fields = [
        { ...reportsFields.Id, checked: true },
        { ...reportsFields.CourseId, checked: true },
        { ...reportsFields.ReportDate, checked: true },
        { ...reportsFields.StudentId, checked: true },
        { ...reportsFields.StudentName, checked: true },
      ];

      this.tabs = [
        {
          id: "AllCourseReports",
          title: "כל הדוחות",
          getDataAsync: this.getAllReports,
          perPage: 100,
          fields,
          filters: [
            {
              ...reportsfilters.SearchReportFilterType,
            },
            {
              ...reportsfilters.StudentId,
            }
          ],
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: true,
          tableActions: [
            TableActions.deleteReports,
          ],
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש מהיר",
          },
          sideViewEntity: null,
          isAdminArea: true,
          defaultQuery: false
        },
      ];
    },
    onNewEntitySave() {
      this.$refs.entity.getData(this.tabs[0])
    },
    getAllReports({ cancelToken, ...rest }) {
      return CoursesService.getCourseReports(
        {
          CourseId: this.$store.state.auth.course?.id,
          ...rest,
          IsAscending: false
        },
        cancelToken.token
      ).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    loadReports() {
      this.$refs.entity.getData(this.tabs[0]);
    },
    addReport() {
      Modal.open({
        component: DialogAddReport,
        props: {
          store: this.$store,
          onSuccess: this.onNewEntitySave
        },
        events: {
          customEvent: () => {
            this.loadReports();
          }
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.Reports__View {
  height: 100%;

  .link-button {
    background: #ff7a59;
    border: none;
    border-radius: 3px;
    padding: 14px 25px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }
}
</style>
